// 申遗承诺
import SHZT from './sn-table-column-shzt';
import DJZT from './sn-table-column-djzt';

const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [
                {
                    label: "年份",
                    prop: "ND",
                    minWidth: "",
                    sortable: 'custom'
                },
                {
                    label: "总人口",
                    prop: "ZRK",
                    minWidth: ""
                }, {
                    label: "耕地面积（亩）",
                    prop: "GDMJ",
                    minWidth: ""
                }, {
                    label: "农作物产量（公斤）",
                    prop: "NZWCL",
                    minWidth: ""
                }, {
                    label: "茶园面积（亩）",
                    prop: "CYMJ",
                    minWidth: ""
                }, {
                    label: "茶叶总产量（公斤）",
                    prop: "CYZCL",
                    minWidth: ""
                }, {
                    label: "经济总收入（元）",
                    prop: "JJZCR",
                    minWidth: ""
                }, {
                    label: "人均总收入（元）",
                    prop: "RJZSR",
                    minWidth: ""
                },
                // {
                //     label: "年度",
                //     prop: "ND",
                //     minWidth: ""
                // },
                // {
                //     label: "人口密度(人/每平方公里)",
                //     prop: "RKMD",
                //     minWidth: ""
                // },
                // {
                //     label: "人均GDP(美元)",
                //     prop: "RJGDP",
                //     minWidth: ""
                // },
                // {
                //     label: "国家保护动植物种类(项)",
                //     prop: "GJBHDZWZL",
                //     minWidth: ""
                // },
                // {
                //     label: "植被覆盖率(%)",
                //     prop: "ZBFGL",
                //     minWidth: ""
                // },
                // {
                //     label: "遗产所在地严重污染工业企业数量(个)",
                //     prop: "YCSZDYZWRGYQYSL",
                //     minWidth: ""
                // },
                SHZT,
                DJZT
            ],
            // 操作列
            columnsBtn: {
                buttonData: []
            }
        },
    },
    snSearch: {
        inputData: [{
            label: '关键字',
            placeholder: "请输入关键字",
            value: 'keyword',
            operateType: 'search',
            isShow: true,
            default: ""
        }],
    },
    snButton: {
        buttonData: [
            {
                isShow: true,
                btnType: 'button',
                operateType: 'buttonNew',
                showMethod: 'showNewBtn',
                name: '新建',
                round: true,
                backColor: '#28ccd9',
            },
            {
                btnType: 'button',
                operateType: 'buttonExport',
                showMethod: 'showExportBtn',
                name: '导出',
                round: true,
                backColor: '#ffcc66',
                color: '#fff'
            }
        ]
    }
};
export default selfData;